<template>
    <div :class="`listCard ${change}`" @click="viewPortfolio">
        <div class="lcHeader">
            <div class="lcName">{{portfolio.name}}</div>
            <div class="lcDesc">{{portfolio.description}}</div>
        </div>
        <div class="lcBody">
            <div class="portfolioCardPart">
                <div class="pcp-value">
                    <div class="pcpPriceInfo">
                        <div class="pcpCurrency">{{ portfolioCurrency }}</div>
                        <div class="pcpValue">{{ portfolioValue }}</div>
                    </div>
                    <div v-if="portfolio.stocks.length > 0" class="pcp-priceChange">
                        <StockPriceChange :stockChangeInfo="stockChangeInfo"></StockPriceChange>
                    </div>
                </div>
            </div>
        </div>
        <font-awesome-icon :icon="icons.portfolio" class="fa-fw bgIcon" color="#DDD"></font-awesome-icon>
    </div>
</template>

<script>
import portfolioCardMixin from './PortfolioCardMixin';
export default {
    props: ['portfolio'],
    mixins: [portfolioCardMixin]
}
</script>

<style lang="scss" scoped>
.listCard {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #FFF;
    min-width: 0;
    border-top: solid 5px #666;
    &.up {
        border-color: var(--v-greenUp-base);
    }
    &.down {
        border-color: var(--v-redDown-base);
    }
    .lcHeader {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        z-index: 1;

        .lcName {
            font-size: 1em;
            font-weight: bold;
        }
        .lcDesc {
            font-size: 0.7em;
            color: #777;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .lcBody {
        flex-shrink: 0;
        width: 150px;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        z-index: 1;
        .portfolioCardPart {
            .pcp-value {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: 1em;
                .pcpPriceInfo {
                    font-family: "Roboto", sans-serif;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .pcpCurrency {
                        font-size: 0.8em;
                        color: #777;
                    }
                    .pcpValue {
                        font-size: 1.5em;
                    }
                }
                .pcp-priceChange {
                    font-size: 0.9em;
                }
            }
        }
    }
    .bgIcon {
        position: absolute;
        bottom: -10px;
        right: 160px;
        font-size: 50px;
        rotate: -15deg;
    }
}
</style>