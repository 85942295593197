<template>
    <div class="portfolioPage">
        <div class="section">
            <PortfolioOverview :portfolio="portfolio" class="ppOverview"></PortfolioOverview>
            <ImportPortfolioDialog :portfolio="portfolio"></ImportPortfolioDialog>
        </div>
        <PortfolioList :portfolio="portfolio" class="ppList"></PortfolioList>
    </div>
</template>

<script>
import ImportPortfolioDialog from '../../components/portfolio/ImportPortfolioDialog.vue';
import PortfolioList from '../../components/portfolio/PortfolioList.vue'
import PortfolioOverview from '../../components/portfolio/PortfolioOverview.vue';
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
import { listComputed } from '../../store/helpers/ListHelper';
export default {
    components: { PortfolioList, PortfolioOverview, ImportPortfolioDialog },
    mixins: [UserDataHelperMixin],
    data() {
        return {
            selectedPortfolioId: this.$route.params.id
        }
    },
    computed: {
        ...listComputed,
        portfolio() {
            return this.userLists.portfolios.find(p => p.id === this.selectedPortfolioId)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.portfolioPage {
    padding: 20px 0;
    display: flex;
    gap: 25px;
    .section {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .ppOverview {
        width: 350px;
    }
    .ppList {
        flex: 1;
        position: relative;
    }
    @media screen and (max-width: $small) {
        & {
            padding: 5px;
            flex-direction: column;
            .ppOverview {
                width: 100%;
            }
        }
    }
}
</style>