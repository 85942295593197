<template>
    <div class="portfolioStockList">
        <div v-if="false" class="entryList">
            <div class="entriesHeader">
                <div class="entryHeader"></div>
                <div class="entryHeader eh-center aright">
                    <div>{{ $t('UserStockWatchingList.portfolio.dayGain') }}</div>
                    <div>{{ $t('UserStockWatchingList.portfolio.totalGain') }}</div>
                </div>
                <div class="entryHeader eh-actions">
                    <v-btn color="blue darken-4" fab dark small absolute top class="addButtonMobile" @click="addStock">
                        <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
            <div v-for="entry in stocksToShow" :key="`${entry.stockId}-entry`" class="entry" @click="editStock(entry)">
                <div class="entryPart ep-left">
                    <div class="entrySymbol">{{entry.stockDateTimeSnapshot.symbol}}</div>
                    <div class="entryName">{{entry.stockDateTimeSnapshot.compagnyName}}</div>
                </div>
                <div class="entryPart ep-center">
                    <StockDayChange :stock="entry"></StockDayChange>
                    <StockTotalChange :stock="entry"></StockTotalChange>
                </div>
                <div class="entryPart ep-right">
                    <StockPriceInfos :entry="stockPriceInfo(entry)"></StockPriceInfos>
                    <StockPriceChange :stockChangeInfo="stockChangeInfo(entry)"></StockPriceChange>
                </div>
            </div>
        </div>
        <EmptyListWidget v-else @addAsked="addStock"></EmptyListWidget>
        <ConfirmDialog ref="deleteItemConfirmDialog" @answered="deleteItemDialogAnswered"></ConfirmDialog>
        <Alert ref="iAlert"></Alert>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
    </div>
</template>

<script>
import Alert from '../controls/Alert.vue';
import ConfirmDialog from '../controls/ConfirmDialog.vue';
import SySnackbar from '../controls/SySnackbar.vue';
import EmptyListWidget from '../list/EmptyListWidget.vue';
import StockDayChange from '../stocks/StockDayChange.vue';
import StockPriceChange from '../stocks/StockPriceChange.vue';
import StockPriceInfos from '../stocks/StockPriceInfos.vue';
import StockTotalChange from '../stocks/StockTotalChange.vue';
import userStockListMixin from '../userStockWatchingList/UserStockListMixin';

export default {
    props: ["portfolioStock"],
    mixins: [userStockListMixin],
    computed: {
        stocksToShow() {
            return this.portfolioStock ? this.portfolioStock.stocks.filter(s => s.stockDateTimeSnapshot) : [];
        }
    },
    methods: {
        editStock(item) {
            this.$emit("editStock", item);
        },
        stockPriceInfo(stock) {
            return {
                currency: stock.stockDateTimeSnapshot.currency.toUpperCase(),
                lastPrice: stock.stockDateTimeSnapshot.price
            }
        },
        stockChangeInfo(stock) {
            return {
                changeAmount: stock.stockDateTimeSnapshot.change,
                changePercent: stock.stockDateTimeSnapshot.changePercent
            }
        },
        addStock() {
            this.$emit('addStock')
        }
    },
    components: { ConfirmDialog, SySnackbar, Alert, StockPriceInfos, StockPriceChange, StockDayChange, StockTotalChange, EmptyListWidget }
}
</script>

<style lang="scss" scoped>
.portfolioStockList {
    .entryList {
        display: flex;
        flex-direction: column;
        .entriesHeader {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 0.6em;
            .entryHeader {
                flex: 3;
                position: relative;
                &.eh-center {
                    flex: 2;
                }
                &.aright {
                    text-align: right;
                }
                &.eh-actions {
                    text-align: right;
                    .addButtonMobile {
                        position: relative;
                        right: 0;
                        top: -15px;
                    }
                }
            }
        }
        .entry {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px;
            border-bottom: solid 1px #EEE;
            .entryPart {
                font-size: 0.8em;
                flex: 3;
                &.ep-left {}
                &.ep-center {
                    flex: 2;
                    font-size: 0.7em;
                    text-align: right;
                }
                &.ep-right {
                    text-align: right;
                }
                .entrySymbol {
                    font-weight: bold;
                }
                .entryName {
                    font-size: 0.8em;
                    color: var(--midGray);
                }
                .sdcLabel {
                    font-size: 0.8em;
                }
                ::v-deep .priceInfos {
                    justify-content: flex-end;
                    .currency{
                        font-size: 0.7em;
                    }
                }
            }
        }
    }
}
</style>