<template>
    <div class="SubscriptionEnd">
        <div v-if="subscriptionDaysLeft" class="cancelledSubscription change">
            * {{ $tc('account.sections.subscriptionBilling.cancelledSubscription', subscriptionDaysLeft, { n: subscriptionDaysLeft }) }}
        </div>
        <div v-if="plannedChangeOfPlan" class="changePlanned change" v-html="newPlanDescription"></div>
    </div>
</template>

<script>
import DateHelper from "../../infra/DateHelper"
import { authComputed } from "../../store/helpers/AuthenticationHelper"
export default {
    data() {
        return {
            icons: {
            },
            subscription: undefined,
            subscriptionDaysLeft: '',
            canCancel: false
        }
    },
    created() {
        this.calculateSubscriptionDaysLeft();
    },
    computed: {
        ...authComputed,
        plannedChangeOfPlan() {
            return this.$store.getters.plannedChangeOfPlan
        },
        newPlanDescription() {
            if (this.plannedChangeOfPlan) {
                const planDate = DateHelper.toLocaleSimpleDate({ stringDate: this.plannedChangeOfPlan.date, locale: this.$t('locale') })
                return this.$t('account.sections.subscriptionBilling.changePlan', [this.plannedChangeOfPlan.planName, planDate])
            }
            return ''
        },
        newPlanDate() {
            return this.plannedChangeOfPlan ? this.plannedChangeOfPlan.date : ''
        }
    },
    methods: {
        calculateSubscriptionDaysLeft() {
            this.subscriptionDaysLeft = this.isTerminationInfoLoaded() ? DateHelper.daysBetween(new Date().toDateString(), this.userSubscriptionInfo.terminationInfo.terminationDate) : '';
        },
        isTerminationInfoLoaded() {
            return this.userSubscriptionInfo && this.userSubscriptionInfo.terminationInfo;
        }
    },
    watch: {
        userSubscriptionInfo: {
            handler: function(val) {
                this.calculateSubscriptionDaysLeft();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/breakpoints.scss";
.SubscriptionEnd {
    font-size: 0.8em;
    .change {
        border-radius: 3px;
        padding: 8px;
        text-align: center;
        margin-top: 5px;

        &.cancelledSubscription {
            background-color: var(--v-warning-base);
            color: #FFF;
        }
        &.changePlanned {
            background-color: var(--v-warning-base);
            color: #FFF;
        }
        ::v-deep .bold {
            font-weight: bold;
        }
    }
}
</style>
