<template>
    <div id="chart">
        <div class="chartHeader">
            <v-btn-toggle v-model="activeInterval" mandatory dense tile group color="primary" @change="intervalChanged">
                <v-btn v-for="(interval, index) in intervals" :key="index" :value="interval.key">
                    {{interval.quantity}}{{$t(`units.${interval.unit}Abbrev`)}}
                </v-btn>
            </v-btn-toggle>
            <div class="chartTitle">{{title}}</div>
        </div>
        <apexchart ref="stockChart" width="100%" type="area" :options="chartOptions" :series="series" class="stockChart"></apexchart>
    </div>
</template>

<script>
import DateHelper from '../../infra/DateHelper';
import StockEvolutionService from '../../services/StockEvolutionService';
import StockHtmlHelper from '../stocks/StockHtmlHelper';
import StockChartBuilder from './StockChartBuilder';
export default {
    props: ['stock'],
    data () {
        return {
            title: undefined,
            series: [],
            activeInterval: undefined,
            intervals: [],
            chartOptions: {
                xaxis: {
                    type: 'datetime'
                }
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        StockEvolutionService.getAvailableIntervals().then(data => {
            this.intervals = data;
            this.activeInterval = data[2];
        });
        this.chartBuilder = new StockChartBuilder({
            yAxisLabelFormatter: price => { return this.stockHtmlHelper.formatPrice(price); },
            isMobile: this.$vuetify.breakpoint.mobile
        });
    },
    computed: {},
    methods: {
        setData({ name, data, previousClose }) {
            const d = data.length > 0 ? data[0][0] : new Date().toDateString();
            this.title = DateHelper.toSimpleDate(d);
            const hourScale = this.activeInterval === 'day';
            const chart = this.chartBuilder.buildChart({ name, data, activeInterval: this.activeInterval, previousClose, locale: this.$i18n.locale, hourScale });
            this.chartOptions = chart.options;
            this.series = chart.series;
        },
        reset() {
            this.series = [];
            this.chartOptions = {
                xaxis: {
                    type: 'datetime'
                }
            };
        },
        intervalChanged() {
            StockEvolutionService.getInterval({ stockId: this.stock.stockId, interval: this.activeInterval }).then(response => {
                const sepit = response.stockEvolutionPITs
                const data = sepit.evolutionPITs.filter(e => e.price).map(s => { return [new Date(s.dateTime + 'Z').toLocaleString(), s.price] })
                const previousClose = response.previousClosePrice;
                this.setData({
                    name: this.stock.symbol,
                    data,
                    previousClose
                });
            });
        }
    }
}
</script>

<style scoped>
.chartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    font-size: small;
    padding: 0 10px;
}
.stockChart {
    display: flex;
    justify-content: center;
    background-color: #FFF;
}
@media screen and (max-width: 750px)  {
    .chartHeader {
        padding: 8px;
        text-align: start;
    }
    .chartTitle {
        padding: 4px;
        font-size: smaller;
    }
}
</style>
