<template>
    <v-card elevation="2" class="imageCard">
        <div class="icImage">
            <v-img
                max-height="100"
                max-width="100"
                :src="infos.img"
            ></v-img>
        </div>
        <v-card-text class="icText">{{$t(infos.text)}}</v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['infos']
}
</script>

<style scoped>
.imageCard {
    width: 12vw;
    background-color: rgba(0, 0, 0, 0.3);
}
.icImage {
    display: flex;
    justify-content: center;
    padding: 30px 0 20px;
}
.icText {
    color: #FFF !important;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
}
@media screen and (max-width: 1264px) {
    .imageCard {
        width: 18vw;
    }
}
@media only screen and (device-width: 768px) {
/* For general iPad layouts */
    .imageCard {
        width: 40%;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
/* For landscape layouts only */
    .imageCard {
        width: 40%;
    }
}
@media screen and (max-width: 750px) {
    .imageCard {
        width: 100%;
    }
}
</style>
