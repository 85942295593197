import AuthUserJWT from '../infra/AuthUserJWT';
import AuthenticatedUser from '../models/AuthenticatedUser';
import serverService from './ApiWrapper'
import storageService from './StorageService';
import PlanConverter from '../infra/converters/PlanConverter';

var SubscriptionService = {
    getUserSubscriptionInfo: ({ userId }) => {
        return serverService.post('/api/Subscription/GetUserSubscriptionInfo', { userId })
    },
    getPublicSubscriptionList: () => {
        return new Promise((resolve, reject) => {
            serverService.post('/api/Basics/GetPublicSubscriptionList').then((result) => {
                const data = result.map((plan) => PlanConverter.convert(plan));
                data.sort((plan1, plan2) => {
                    if (plan1.displayOrder < plan2.displayOrder) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                })
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    createSubscription: ({ priceId }) => {
        return serverService.post('/api/Subscription/createSubscription', { priceId });
    },
    cancelSubscription: () => {
        return serverService.post('/api/Subscription/cancelSubscription');
    },
    createCheckoutSession: ({ priceId }) => {
        return serverService.post('/api/Subscription/createCheckoutSession', { priceId });
    },
    createUpdateSubscriptionCheckoutSession: () => {
        return serverService.post('/api/Subscription/createUpdateSubscriptionCheckoutSession', {});
    },
    createSetupIntent: () => {
        return serverService.post('/api/Subscription/createSetupIntent');
    },
    calculateSubscriptionChangeProrata: ({ newPlanName }) => {
        return serverService.post('/api/Subscription/calculateSubscriptionChangeProrata', { newPlanName })
    },
    upgradeOrDowngrade: ({ newPlanName }) => {
        return serverService.post('/api/Subscription/upgradeOrDowngrade', { newPlanName })
    },
    regenerateTokenToHandleSubscriptionChanged: () => {
        return new Promise((resolve, reject) => {
            serverService.post('/api/Subscription/regenerateTokenToHandleSubscriptionChanged').then((response) => {
                const newAuthUserJWT = new AuthUserJWT(response.token);
                const storageAuthUser = storageService.AuthUser;
                if (storageAuthUser) {
                    const authUser = new AuthenticatedUser(storageAuthUser);
                    const storedAuthUserJWT = new AuthUserJWT(authUser.token);
                    if (newAuthUserJWT.subscriptionInfos.subscriptionPlanId !== storedAuthUserJWT.subscriptionInfos.subscriptionPlanId) {
                        serverService.addToken({ token: response.token });
                        authUser.token = response.token;
                        storageService.AuthUser = authUser.serialize();
                    }
                }
                resolve(newAuthUserJWT.subscriptionInfos);
            }).catch((error) => {
                // TODO valider si 403
                reject(error);
            });
        });
    }
}

export default SubscriptionService
