<template>
    <div class="StockThresholdPanel">
        <SyPanel :title="stock.listName" :icon="stock.listType">
            <template v-slot:details>
                <div :class="`thScore ${getScoreColor()}`">{{getCleanScore()}}</div>
            </template>
            <ThresholdPanel :stock="stock" :stockListId="stock.listId" class="thPanel"></ThresholdPanel>
            <template v-slot:footer>
                <v-btn color="red" text small dark @click="deleteEntry" class="deleteButton">
                    <font-awesome-icon :icon="icons.delete" class="fa-fw fa-lg mr-2"></font-awesome-icon>
                    {{ $t('UserStockWatchingList.buttons.deleteFromList', [stock.symbol, stock.listName]) }}
                </v-btn>
            </template>
        </SyPanel>
        <ConfirmDialog ref="deleteItemConfirmDialog" @answered="deleteItemDialogAnswered"></ConfirmDialog>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import ThresholdPanel from './edition/ThresholdPanel.vue'
import SyPanel from '../controls/SyPanel.vue'
import Alert from '../controls/Alert.vue';
import ConfirmDialog from '../controls/ConfirmDialog.vue';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import StockHtmlHelper from '../stocks/StockHtmlHelper';
export default {
    components: { ThresholdPanel, SyPanel, Alert, ConfirmDialog },
    props: ["stock"],
    data() {
        return {
            icons: {
                delete: faTrashAlt
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    methods: {
        getCleanScore() {
            return this.stockHtmlHelper.cleanScore(this.stock.score);
        },
        getScoreColor() {
            return this.stockHtmlHelper.getBadgeColor(this.stockHtmlHelper.getBadge(this.stock.score));
        },
        deleteEntry() {
            this.$refs.deleteItemConfirmDialog.open({
                title: this.$t('EditorPanel.actions.deleteItem.title'),
                text: this.$t('EditorPanel.actions.deleteItem.text', [this.stock.symbol, this.stock.listName])
            })
        },
        deleteItemDialogAnswered(answer) {
            if (answer.response === true) {
                this.$store.dispatch('deleteStockFromWatchList', { listId: this.stock.listId, entryId: this.stock.id, listType: this.stock.listType }).then(() => {
                    console.log('deleteStockFromWatchList done');
                }).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.StockThresholdPanel {
    position: relative;
    .thScore {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: var(--t000);
        line-height: 1.2;
        font-size: 1.5em;
        &.t600{
            color: var(--t600);
        }
        &.t800{
            color: var(--t800);
        }
        &.t400-sell {
            color: var(--t400-sell);
        }
        &.t200-sell {
            color: var(--t200-sell);
        }
        &::after {
            margin-left: 5px;
            content: "pts";
            font-size: 1rem;
            font-weight: normal;
        }
    }
}
</style>