<template>
    <div class="welcomePage">
        <div class="bgImage"></div>
        <div class="auth">
            <LanguageSwitcher :large="!$vuetify.breakpoint.mobile" dark></LanguageSwitcher>
            <LoginAsGuest></LoginAsGuest>
            <v-btn color="primary" :small="$vuetify.breakpoint.xs" :large="$vuetify.breakpoint.lgAndUp" class="loginBtn" @click="loginAsked">{{$t('Home.welcome.buttons.login')}}</v-btn>
        </div>
        <div class="wHeader">
            <img src="/invagora_small_light.png" class="homeLogo"/>
        </div>
        <div class="wBody">
            <div class="wBodyBox">
                <div class="wMessage">{{$t('Home.welcome.message')}}</div>
                <div class="wBodyCore">
                    <div class="wMarketing">
                        <ImageCard v-for="(infos, i) in marketing" :key="i" :infos="infos"></ImageCard>
                    </div>
                    <div class="wSignUpCont">
                        <div class="wSignUp">
                            <div class="wSignUpMsg">{{$t('Home.welcome.signUpMessage')}}</div>
                            <div class="signUpBtn" @click="registerAsked">{{$t('Home.welcome.buttons.register')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="welcomeFooter">
            <div class="poweredby" @click="clickSynaxy">
                <span>{{$t('Home.welcome.footer.poweredBy')}}</span>
                <v-img :src="require('../assets/synaxy_all_white.svg')"  position="left center" contain width="50" height="50"/>
            </div>
        </div>
    </div>
</template>

<script>
import ImageCard from '@/components/controls/ImageCard.vue';
import LanguageSwitcher from '@/components/controls/LanguageSwitcher'
import LoginAsGuest from '../components/controls/LoginAsGuest.vue';
export default {
    data() {
        return {
            marketing: [
                {
                    img: '/images/target.png',
                    text: 'Home.welcome.marketing.target'
                },
                {
                    img: '/images/clock.png',
                    text: 'Home.welcome.marketing.delayed'
                },
                {
                    img: '/images/briefcase.png',
                    text: 'Home.welcome.marketing.portfolios'
                },
                {
                    img: '/images/cabinet.png',
                    text: 'Home.welcome.marketing.lists'
                }
            ]
        }
    },
    components: { ImageCard, LanguageSwitcher, LoginAsGuest },
    methods: {
        loginAsked() {
            this.$router.push({ name: 'Login' });
        },
        registerAsked() {
            const q = this.$route.query.inviteToken ? { query: this.$route.query } : null;
            this.$router.push({ name: 'RegisterForm', ...q });
        },
        clickSynaxy() {
            window.open('https://www.synaxy.com/', '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.welcomePage {
    height: 100%;
    background: #000;  /* fallback colour. Make sure this is just one solid colour. */
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    .bgImage {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(20,20,20, .75), rgba(20,20,20, .60)), url("/agora.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 100%;
    }
    .auth {
        z-index: 8;
    }
}
.wHeader {
    height: 200px;
    padding: 30px;
    display: flex;
    z-index: 5;
}
.wBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.wBodyBox {
    width: 80vw;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.wBodyCore {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.wMessage {
    font-size: 2.5rem;
    text-align: center;
}
.wSignUpCont {
    flex: 1;
    display: flex;
    justify-content: center;
}
.wSignUp {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 2rem;
    max-height: 30vh;
    padding: 30px;
}
.wSignUpMsg {
    color: #FFF;
    text-align: center;
    font-size: larger;
}
.wMarketing {
    flex: 3;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}
.homeLogo{
	height: 150px;
    max-height: 10vh;
    min-height: 100px;
}
.auth {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
}
.auth>* {
    margin-left: 15px;
}
.loginBtn {
    margin-left: 15px;
}
.signUpBtn {
    background-color: #1976d2;
    font-size: xx-large;
    padding: 15px 25px;
    text-transform: uppercase;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    text-align: center;
}
.signUpBtn:hover {
    opacity: 0.9;
}
.welcomeFooter {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}
.poweredby {
    display: flex;
    align-items: center;
}
.poweredby:hover{
    cursor: pointer;
}
@media screen and (max-height: 1100px) {
    .wHeader {
        height: 180px;
    }
    .wSignUp {
        max-height: 40vh;
    }
    .wSignUpMsg {
        font-size: 1.8rem;
    }
    .signUpBtn {
        font-size: x-large;
    }
}
@media screen and (max-width: 2000px) {

    .wSignUpMsg {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
    .signUpBtn {
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 1600px) {
    .wBody {
        width: 95%;
    }
    .homeLogo{
        top: 40px;
        height: 100px;
        min-height: 0;
    }
    .wMessage {
        font-size: 2rem;
    }

}
@media screen and (max-width: 1264px) {
    .wHeader {
        height: 150px;
        padding: 20px;
    }
    .wBody {
        width: 100%;
    }
    .wBodyBox {
        width: 80vw;
    }
    .wBodyCore {
        flex-direction: column-reverse;
        gap: 30px;
    }
}
@media only screen and (device-width: 768px) {
/* For general iPad layouts */
    .wBodyCore {
        flex-direction: column-reverse;
    }
    .wMessage {
        font-size: 1.8rem;
        margin-bottom: 30px;
    }
    .wMarketing>* {
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .wSignUpCont {
        margin-bottom: 30px;
    }
    .wSignUpMsg {
        margin-bottom: 15px;
        font-size: 1.6rem;
    }
    .signUpBtn {
        font-size: 1.4rem;
        padding: 10px 20px;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
/* For landscape layouts only */
    .wMessage {
        font-size: 1.8rem;
        margin-bottom: 30px;
    }
    .wSignUp {
        width: 100%;
    }
    .wSignUpMsg {
        margin-bottom: 15px;
        font-size: 1.6rem;
    }
    .signUpBtn {
        font-size: 1.4rem;
        padding: 10px 20px;
    }
}
@media screen and (max-width: 750px) {
    .welcomePage {
        font-size: small;
        background-position-x: center;
        background-position-y: 0;
        background-attachment: fixed;
        .bgImage {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(20,20,20, .75), rgba(20,20,20, .60)), url("/agora.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 100%;
        }
    }
    .auth {
        position: unset;
        padding: 10px;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .wHeader {
        justify-content: center;
    }

    .homeLogo{
        top: 80px;
        left: 10px;
        height: auto;
        min-height: 0;
    }
    .wBody {
        width: 100%;
        padding: 0 30px;
    }
    .wMessage {
        font-size: 1.5rem;
        margin-bottom: 30px;
    }
    .wBodyCore {
        flex-direction: column-reverse;
    }

    .wMarketing {
        flex-direction: column;
    }
    .wMarketing>* {
        margin-right: 0;
    }
    .wSignUpCont {
        margin-bottom: 30px;
    }
    .wSignUpMsg {
        margin-bottom: 15px;
        font-size: 1.4rem;
    }
    .wSignUp {
        margin-bottom: 0;
        width: 100%;
    }
    .signUpBtn {
        font-size: 1.1rem;
    }
}
</style>
