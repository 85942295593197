import {
    faUserShield
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faPaperPlane,
    faCog,
    faTachometerAlt,
    faCommentAltLines,
    faFlask
} from "@fortawesome/pro-light-svg-icons";
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import InvitePanel from "../../components/invitation/InvitePanel"
import MaintenanceSection from "./Maintenance/MaintenanceSection"
import CommentSection from './Comments/CommentSection'
import UsageSection from './Usage/UsageSection'
import TestsSection from './Tests/TestsSection.vue'
var AdministrationPageMixin = {
    data() {
        return {
            icons: {
                admin: faUserShield,
                invite: faPaperPlane,
                maintenance: faCog,
                usage: faTachometerAlt,
                comment: faCommentAltLines,
                tests: faFlask
            }
        }
    },
    computed: {
        ...authComputed
    },
    created() {
    },
    components: {
        InvitePanel,
        MaintenanceSection,
        UsageSection,
        CommentSection,
        TestsSection
    }
}

export default AdministrationPageMixin
