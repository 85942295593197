<template>
    <div class="portfolioList">
        <div class="portfolioListStocks">
            <PortfolioStockListMobile v-if="$vuetify.breakpoint.mobile" :portfolioStock="portfolio" @editStock="editStock" @addStock="addStock"></PortfolioStockListMobile>
            <InvaList v-else :portfolioStock="portfolio" @editStock="editStock" @addStock="addStock"></InvaList>
        </div>
        <PortfolioAddStockDialog ref="portfolioAddStockDialog" :portfolioId="portfolioId"></PortfolioAddStockDialog>
    </div>
</template>

<script>
import InvaList from '../list/InvaList.vue';
import PortfolioAddStockDialog from './PortfolioAddStockDialog.vue';
import PortfolioStockListMobile from './PortfolioStockListMobile.vue';

export default {
    components: { PortfolioAddStockDialog, InvaList, PortfolioStockListMobile },
    props: ['portfolio'],
    data() {
        return {
            selectedStock: undefined
        }
    },
    computed: {
        portfolioId() {
            return this.portfolio ? this.portfolio.id : ''
        }
    },
    methods: {
        addStock() {
            this.$refs.portfolioAddStockDialog.show()
        },
        editStock(stock) {
            this.selectedStock = stock
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: this.portfolioId });
            this.$router.push({ name: 'Stock', params: { id: this.selectedStock.stockId } });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.portfolioList {
    padding: 20px 0;
    display: flex;
    gap: 25px;
    .overview {
        width: 350px;
    }
    .portfolioListStocks {
        flex: 1;
        position: relative;
        .portfolioListStock {
        }
    }
    @media screen and (max-width: $small) {
        & {
            padding: 5px;
            flex-direction: column;
            .overview {
                width: 100%;
            }
        }
    }

}
</style>