<template>
    <div class="orderItemCont">
        <div class="orderItem" v-if="!$vuetify.breakpoint.mobile">
            <div class="oi-img">
                <font-awesome-icon :icon="icons[item.name]"></font-awesome-icon>
            </div>
            <div class="oi-desc">
                <div class="oi-name">{{$t(`subscriptions.plans.${item.name}.fullTitle`)}}</div>
                <div class="oi-detail">{{$t(`subscriptions.confirmation.billingPeriod.${item.reccurringInterval}`)}}</div>
                <div class="oi-detail">{{$t('subscriptions.confirmation.renewal', [renewalDate])}}</div>
            </div>
            <div class="oi-price">{{formattedPrice}}</div>
        </div>
        <div class="oi" v-else>
            <div class="oi-desc">
                <div class="oi-name">{{$t(`subscriptions.plans.${item.name}.fullTitle`)}}</div>
                <div class="oi-detail">{{$t(`subscriptions.confirmation.billingPeriod.${item.reccurringInterval}`)}}</div>
            </div>
            <div class="oi-price">{{formattedPrice}}</div>
        </div>
    </div>
</template>

<script>
import planHelperMixin from '../../helpers/planHelperMixin';
export default {
    props: ['item', 'date'],
    mixins: [planHelperMixin],
    computed: {
        renewalDate() {
            return new Date(this.date).toLocaleDateString(this.$t('locale'))
        },
        formattedPrice() {
            let fprice;
            if (this.item && this.item.reccurringInterval === 'month') {
                fprice = this.$t('subscriptions.confirmation.monthlyPrice', [this.item.price.toFixed(2)]);
            }
            return fprice;
        }
    }
}
</script>

<style scoped>
.orderItem {
    display: flex;
}
.oi-img {
    color: var(--agora);
    margin-right: 5px;
    padding: 0 10px;
    font-size: x-large;
}
.oi-desc{
    flex: 1;
}
.oi-name {
}
.oi-detail {
    font-size: smaller;
    font-style: italic;
    color: var(--midGray);;
}
</style>
