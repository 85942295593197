<template>
    <div class="StockAiAnalysis">
        <div v-if="hasAiPlan" class="main">
            <div class="section">
                <SyPanel :title="$t('aiAnalysis.sentiments.title')" icon="sentiments">
                    <StockSentimentsPanel></StockSentimentsPanel>
                </SyPanel>
                <SyPanel :title="$t('aiAnalysis.similarCompanies.title')" icon="company">
                    <CompanyPanel></CompanyPanel>
                </SyPanel>
            </div>
            <div class="section">
                <SyPanel :title="$t('aiAnalysis.partnerships.title')" icon="partnership">
                    <PartnershipsPanel></PartnershipsPanel>
                </SyPanel>
                <SyPanel :title="$t('aiAnalysis.questionsBeforeInvesting.title')" icon="questions">
                    <QuestionsBeforeInvestingPanel></QuestionsBeforeInvestingPanel>
                </SyPanel>
            </div>
        </div>
        <LockedControl v-else :minPlan="minPlan"></LockedControl>
    </div>
</template>

<script>
import QuestionsBeforeInvestingPanel from '../controls/AI/QuestionsBeforeInvestingPanel.vue';
import CompanyPanel from '../controls/CompanyPanel.vue';
import LockedControl from '../controls/LockedControl.vue';
import PartnershipsPanel from '../controls/PartnershipsPanel.vue';
import StockSentimentsPanel from '../controls/StockSentimentsPanel.vue';
import SyPanel from '../controls/SyPanel.vue';
import StockMixin from './StockMixin';

export default {
    mixins: [StockMixin],
    components: { PartnershipsPanel, CompanyPanel, SyPanel, StockSentimentsPanel, LockedControl, QuestionsBeforeInvestingPanel },
    computed: {
        hasAiPlan() {
            const myplan = this.$store.getters.getMyPlan
            return myplan ? myplan.isAiPlan : false;
        },
        minPlan() {
            const plans = this.$store.getters.getPlans;
            return plans.find(p => {
                return Object.values(p.limit.stockContext.aiData).includes(true);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/breakpoints.scss';
.StockAiAnalysis {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .main {
        display: flex;
        gap: 20px;
        .section {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    @media screen and (max-width: $small){
        .top {
            font-size: 1em;
            .nameAndSymbol {
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
            }
            .price {
                flex-direction: column;
                gap: 5px;
                align-items: flex-end;
                .spi {
                    font-size: 1.2em;
                }
            }
        }
        .main {
            flex-direction: column;
        }
    }
}
</style>