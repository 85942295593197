<template>
    <div class="partnershipLink" @click="linkClicked">
        <v-img v-if="thumbnail" :src="thumbnail" max-height="125" max-width="150" contain></v-img>
        <v-img v-else src="~@/assets/Invagora.png" max-height="125" max-width="150" contain></v-img>
        <div class="linkDesc">
            <div class="ldTitle" v-html="title"></div>
            <div class="ldSnippet">{{ snippet }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['link'],
    data() {
        return {
        }
    },
    computed: {
        thumbnail() {
            return this.link.thumbnail ? this.link.thumbnail.src : ''
        },
        title() {
            return this.link.title ? this.link.title.html : ''
        },
        snippet() {
            return this.link.snippet ? this.link.snippet : ''
        }
    },
    methods: {
        linkClicked() {
            window.open(this.link.link.url, '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.partnershipLink {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 5px;
    cursor: pointer;
    &:hover {
        background-color: var(--v-hover1-base);
    }
    .linkDesc {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .ldTitle {
            font-size: 1em;
            font-weight: bold;
            color: var(--v-agora-base);
        }
        .ldSnippet {
            font-size: 0.9em;
        }
    }
}
</style>