<template>
    <div class="portfolioStockLot" @click="editLot">
        <div class="pslleft">
            <div class="pslQtyAndPrice">{{ qtyAndPrice }}</div>
            <div class="pslDate">{{ date }}</div>
        </div>
        <div class="pslRight" v-if="showGain">
            <div :class="`pslGainPrct${gainClass}`">{{ gainPrct }}</div>
            <div :class="`pslGain${gainClass}`">{{ gain }}</div>
        </div>
    </div>
</template>

<script>
import DateHelper from '../../infra/DateHelper'
import StockHtmlHelper from '../stocks/StockHtmlHelper'

export default {
    props: ['lot', 'lastPrice'],
    data() {
        return {
            stockHTMLHelper: null
        }
    },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper()
    },
    computed: {
        qtyAndPrice() {
            return this.lot ? `${this.lot.portfolioStockTransationType.toUpperCase()} : ${this.lot.quantity} ${this.$tc('dictio.action', this.lot.quantity)} @ ${this.priceByShare(this.lot.averageTransactionPrice)}$` : ''
        },
        date() {
            return this.lot ? DateHelper.toSimpleDate(this.lot.dateOfTransaction) : ''
        },

        gainPrct() {
            return this.lot ? this.stockHTMLHelper.gainPrct({ lastPrice: this.lastPrice, averageTransactionPrice: this.lot.averageTransactionPrice }) : ''
        },
        showGain() {
            return this.lot.portfolioStockTransationType.toLowerCase() === 'buy'
        },
        gain() {
            return this.lot ? this.stockHTMLHelper.gain({ quantity: this.lot.quantity, lastPrice: this.lastPrice, averageTransactionPrice: this.lot.averageTransactionPrice }) : ''
        },
        gainClass() {
            return this.lot ? this.stockHTMLHelper.gainClass({ lastPrice: this.lastPrice, averageTransactionPrice: this.lot.averageTransactionPrice }) : ''
        }
    },
    methods: {
        priceByShare(price) {
            return this.stockHTMLHelper.formatPriceWithDigit(price, 2)
        },
        editLot() {
            this.$emit('editLot', this.lot)
        }
    }
}
</script>

<style lang="scss" scoped>
.portfolioStockLot {
    display: flex;
    // font-size: 0.7rem;
    &>*{
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .pslleft {
        .pslQtyAndPrice {
            font-weight: bold;
        }
        .pslDate {
            font-size: 0.85em;
            color: #999;
        }
    }
    .pslRight {
        align-items: flex-end;
        .negative{
            color: var(--v-redDown-base);
        }
        .positive{
            color: var(--v-greenUp-base);
        }
    }
}
</style>