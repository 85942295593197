<template>
    <div>
        <DashboardMobile v-if="$vuetify.breakpoint.mobile"></DashboardMobile>
        <Dashboard2 v-else></Dashboard2>
    </div>
</template>

<script>
import DashboardMobile from './DashboardMobile.vue';
import Dashboard2 from './Dashboard2.vue';

export default {
	components: { DashboardMobile, Dashboard2 }
}
</script>

<style lang="scss" scoped>

</style>