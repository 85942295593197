import { render, staticRenderFns } from "./StockChart.vue?vue&type=template&id=3c176215&scoped=true&"
import script from "./StockChart.vue?vue&type=script&lang=js&"
export * from "./StockChart.vue?vue&type=script&lang=js&"
import style0 from "./StockChart.vue?vue&type=style&index=0&id=3c176215&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c176215",
  null
  
)

export default component.exports