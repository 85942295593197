<template>
    <div class="simplePage">
        <div class="widget">
            <div class="widgetBody">
                <slot></slot>
            </div>
            <div class="widgetFooter">
                <div class="wfLeft">
                    <slot name="widgetFooterLeftPart"></slot>
                </div>
                <div class="wfRight">
                    <LanguageSwitcher></LanguageSwitcher>
                </div>
            </div>
        </div>
        <div class="simplePageFooter">
            <slot name="simplePageFooter"></slot>
        </div>
    </div>
</template>

<script>
import LanguageSwitcher from '@/components/controls/LanguageSwitcher'
export default {
    data() {
        return {
            valid: true
        };
    },
    components: {
        LanguageSwitcher
    }
}
</script>

<style scoped>
.simplePage {
    height: 100%;
    padding-top: 5vh;
    background-color: var(--lightBG);
}
.widget {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.widgetBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
    border-radius: 10px;
    padding: 40px 0;
}
.widgetFooter {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}
.wfLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}
.simplePageFooter {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-height: 1080px) {
    .simplePage {
        padding-top: 40px;
    }
}
@media only screen and (device-width: 768px) {
/* For general iPad layouts */
    .widget {
        max-width: 80%;
    }
    .widgetBody {
        padding: 0;
    }
}

@media screen and (max-width: 750px) {
    .simplePage {
        padding: 10px;
    }
    .widgetBody {
        padding: 20px 0;
    }
}
</style>
