<template>
    <div>
        <v-btn
            tile
            large
            outlined
            color="error"
            @click="cancelSubscriptionClicked"
        >{{$t('account.sections.subscriptionBilling.cancelSubscription')}}</v-btn>
        <ConfirmDialog ref="cancelSubscriptionDialog" @answered="cancelSubscriptionAnswered"></ConfirmDialog>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import Alert from "../../../components/controls/Alert.vue"
import ConfirmDialog from '../../../components/controls/ConfirmDialog.vue';
import DateHelper from '../../../infra/DateHelper';
import { authComputed } from '../../../store/helpers/AuthenticationHelper';
export default {
    props: ['subscription'],
    data() {
        return {
            dialog: false,
            input: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
            icons: {
                check: faCheck,
                times: faTimes
            }
        };
    },
    computed: {
        ...authComputed
    },
    components: { Alert, ConfirmDialog },
    methods: {
        cancelSubscriptionClicked() {
            const endDate = DateHelper.toLongDate({ stringDate: this.userSubscriptionInfo.currentCycleInfo.endDate, locale: this.$t('locale') });
            this.$refs.cancelSubscriptionDialog.open({
                title: this.$t('account.sections.subscriptionBilling.cancelSubscription'),
                text: [this.$t('account.sections.subscriptionBilling.cancelSubscriptionNote', [endDate]), this.$t('account.sections.subscriptionBilling.cancelSubscriptionQuestion')]
            })
        },
        cancelSubscriptionAnswered({ response }) {
            console.log(response);
            if (response === true) {
                this.$store.dispatch('cancelSubscription').then((updatedSubscription) => {
                    this.$emit('cancelled', { updatedSubscription });
                    this.$refs.iAlert.show({ type: 'info', code: 'SubscriptionCancelled' });
                }).catch(error => {
                    this.$refs.iAlert.error({ errorMsg: error[0] });
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
