<template>
    <div class="pagePolicy">
        <div class="policyTitle">{{$t('policies.cookies.title')}}</div>
        <div class="policyLastUpdated">
            <font-awesome-icon :icon="icons.calendar" class="fa-fw fa-lg"></font-awesome-icon>
            {{$t('policies.lastUpdate')}} {{$t('policies.cookies.update')}}
        </div>
        <div class="policyDescription">
            Our cookies are categorized in the following categories:
Essential cookies.These cookies are essential for enabling user movement around our website. These cookies do not gather information about you that could be used for marketing purposes and do not remember where you have been on the internet. This category of cookies cannot be disabled.
Functionality cookies.Functionality cookies are used to remember information you have entered or choices you made (e. g. language) on the website, so the next time you visit the website you will not have to set them again.
Analytics cookies.These cookies are set to collect information about how visitors use our website. Because of them we can make improvements to our website and report our performance.
Advertising cookies.These cookies are placed by third-party advertising platforms or networks to deliver ads and track ad performance. In some cases, these cookies enable advertising networks to deliver ads that may be relevant to you based upon your activities on our website and other websites (in such cases, ad serving might be based on automated decision-making).
        </div>
    </div>
</template>

<script>
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons"
export default {
    data () {
        return {
            icons: {
                calendar: faCalendarAlt
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/policies.scss";
</style>
