<template>
    <div class="stockSentimentsPanel">
        <apexchart ref="stockChart" width="100%" type="bubble" :options="chartOptions" :series="series" class="stockChart"></apexchart>
        <div class="sentimentDetails" v-html="sentimentDetails"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            chartOptions: {
                annotations: {
                    yaxis: [{
                        y: 0,
                        y2: -100,
                        borderColor: '#775DD0',
                        fillColor: '#FF0000',
                        opacity: 0.1
                    }, {
                        y: 0,
                        y2: 100,
                        borderColor: '#775DD0',
                        fillColor: '#008000',
                        opacity: 0.1
                    }]
                },
                chart: {
                    height: 350,
                    type: 'bubble',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 0.8
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    min: -100,
                    max: 100
                }
            }
        }
    },
    computed: {
        symbol() {
            return this.$store.getters.completeStockInfo ? this.$store.getters.completeStockInfo.symbol : ''
        },
        sentiments() {
            return this.$store.getters.sentiments
        },
        sentimentUsFallbackWasUsed() {
            return this.$store.getters.additionalData ? this.$store.getters.additionalData.sentimentUsFallbackWasUsed : false
        },
        sentimentDetails() {
            return this.sentimentUsFallbackWasUsed ? this.$t('aiAnalysis.sentiments.usFallback', [this.symbol]) : ''
        },
        series() {
            if (!this.sentiments) return []
            const data = this.sentiments.map(s => {
                const y = Math.floor(s.normalized * 100);
                return [s.date, y, s.count]
            })
            return [{
                name: this.$t('aiAnalysis.sentiments.score'),
                data
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
.stockSentimentsPanel {
    padding: 0 10px;
    .sentimentDetails {
        text-align: center;
        margin-bottom: 15px;
        &:empty {
            display: none;
        }
        ::v-deep span {
            font-weight: bold;
        }
    }
}
</style>