import { render, staticRenderFns } from "./StockAiAnalysis.vue?vue&type=template&id=4346773b&scoped=true&"
import script from "./StockAiAnalysis.vue?vue&type=script&lang=js&"
export * from "./StockAiAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./StockAiAnalysis.vue?vue&type=style&index=0&id=4346773b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4346773b",
  null
  
)

export default component.exports